import { type FC } from 'react'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import classes from 'components/Form/Form.module.scss'

import { useFlags } from 'hooks/flags'

import {
  Passphrase,
  SecretKey,
  SecretName,
  SecretValue,
  VaultName
} from 'modules/Common/Fields'
import PrivateKey from 'modules/Common/Fields/PrivateKey/PrivateKey'

import { AgentsSecretsHost } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'
import { type EnvironmentDetailsI } from 'types/FormTypes'

type WarehouseCredentialsT = Pick<
  EnvironmentDetailsI,
  'etlAgent' | 'agentsSecretsManagement'
>

export interface WarehouseAgentCredentialsProps {
  usePrivateKey?: boolean
}

export const WarehouseAgentCredentials: FC<WarehouseAgentCredentialsProps> = ({
  usePrivateKey
}) => {
  const { values } = useFormikContext<WarehouseCredentialsT>()
  const { agentsSecretsManagement } = values
  const agentCloudProvider = values.etlAgent?.agentCloudProvider
  const { projectId } = useParams()
  const { enableAzureMultiKeyVault } = useFlags()
  const SecretValueComponent = usePrivateKey ? (
    <>
      <PrivateKey /> <Passphrase />
    </>
  ) : (
    <SecretValue />
  )

  return (
    <>
      {agentsSecretsManagement === AgentsSecretsHost.MatillionHosted ? (
        SecretValueComponent
      ) : (
        <>
          {agentCloudProvider === AgentCloudProvider.AWS ? (
            <>
              {usePrivateKey ? (
                <>
                  <SecretName
                    fieldName="privateKeySecretName"
                    testName="secret-name-private-key"
                  />
                  <SecretName
                    fieldName="passphraseSecretName"
                    testName="secret-name-passphrase"
                    secretKeyField="passphraseSecretKey"
                    required={false}
                  />
                  <SecretKey
                    fieldName="passphraseSecretKey"
                    testName="secret-key-passphrase"
                    secretNameField="passphraseSecretName"
                    required={false}
                  />
                </>
              ) : (
                <>
                  <SecretName />
                  <SecretKey />
                </>
              )}
            </>
          ) : null}
          {agentCloudProvider === AgentCloudProvider.AZURE ? (
            <>
              {enableAzureMultiKeyVault && (
                <VaultName
                  projectId={projectId ?? ''}
                  agentId={values.etlAgent.id}
                  locationIdFieldName="secretLocationId"
                  className={classNames(classes.Form__SpacingStyles)}
                  testIdPrefix={'warehouse-agent-credentials'}
                />
              )}
              <SecretName />
            </>
          ) : null}
        </>
      )}
    </>
  )
}
