import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { format } from 'date-fns'

import {
  LoadingSpinner,
  Typography,
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import { useGetStreamingPipelineEvents } from 'api/hooks/streaming/useGetStreamingPipelineEvents'

import SortableDataGrid from 'modules/Projects/StreamingPipelineDashboard/Common/components/SortableDataGrid/SortableDataGrid'
import { EventBadge } from 'modules/Projects/StreamingPipelineDashboard/Events/EventBadge/EventBadge'
import { EventExpander } from 'modules/Projects/StreamingPipelineDashboard/Events/EventExpander/EventExpander'
import { EventLog } from 'modules/Projects/StreamingPipelineDashboard/Events/EventLog/EventLog'

import type { StreamingEventLevel, StreamingEventType } from 'types/Streaming'

import classes from './StreamingEventsListing.module.scss'

interface EventGridRow extends DataGridRow {
  id: string
  eventType: StreamingEventType
  eventLevel: StreamingEventLevel
  timestamp: string
  message?: string
}

interface StreamingEventsListingProps {
  pipelineId: string
  projectId: string
}

const StreamingEventsListing: FunctionComponent<
  StreamingEventsListingProps
> = ({ pipelineId, projectId }) => {
  const { t } = useTranslation()

  const [expandedEvents, setExpandedEvents] = useState<Set<string>>(new Set())

  const { data, isLoading, isError } = useGetStreamingPipelineEvents(
    pipelineId,
    projectId
  )

  if (isLoading) {
    return <LoadingSpinner data-testid="loading-spinner" />
  }

  if (!data || isError) {
    return (
      <div
        className={classes.StreamingEventsListing__MessageContainer}
        data-testid="events-error-message"
      >
        <Typography
          format="ts"
          className={classes.StreamingEventsListing__Heading}
        >
          {t('streamingPipelineDashboard.events.error.heading')}
        </Typography>
        <Typography format="bcm">
          {t('streamingPipelineDashboard.events.error.content')}
        </Typography>
      </div>
    )
  }

  if (data.events.length === 0) {
    return (
      <div
        className={classes.StreamingEventsListing__MessageContainer}
        data-testid="no-events-message"
      >
        <Typography
          format="ts"
          className={classes.StreamingEventsListing__Heading}
        >
          {t('streamingPipelineDashboard.events.noEvents.heading')}
        </Typography>
        <Typography format="bcm">
          {t('streamingPipelineDashboard.events.noEvents.content')}
        </Typography>
      </div>
    )
  }

  const columns: Array<DataGridColumnProps<EventGridRow>> = [
    {
      key: 'expander',
      sortable: false,
      title: '',
      as: EventExpander,
      className: classes.StreamingEventsListing__ExpanderColumn,
      mapValues: (value) => ({
        isExpanded: expandedEvents.has(value.id),
        message: value.message,
        eventLevel: value.eventLevel,
        onClick: () => {
          setExpandedEvents((currentExpandedEvents) => {
            const newExpandedEvents = new Set(currentExpandedEvents)
            if (currentExpandedEvents.has(value.id)) {
              newExpandedEvents.delete(value.id)
            } else {
              newExpandedEvents.add(value.id)
            }
            return newExpandedEvents
          })
        }
      })
    },
    {
      key: 'eventType',
      sortable: false,
      title: t('streamingPipelineDashboard.events.fields.eventType'),
      as: EventBadge,
      className: classes.StreamingEventsListing__TypeColumn,
      mapValues: (value) => ({
        eventType: value.eventType,
        eventLevel: value.eventLevel
      })
    },
    {
      key: 'timestamp',
      sortable: true,
      title: t('streamingPipelineDashboard.events.fields.timestamp'),
      as: Typography,
      className: classes.StreamingEventsListing__TimestampColumn,
      mapValues: (row) => ({
        children: format(new Date(row.timestamp), 'dd MMM, HH:mm:ss'),
        format: 'bcs'
      })
    }
  ]

  const rows = data.events.map(
    (event): EventGridRow => ({
      id: event.eventId,
      eventType: event.eventType,
      eventLevel: event.eventLevel,
      timestamp: event.timestamp,
      message: event.message,
      expandedComponent: EventLog,
      expandedComponentProps: { message: event.message },
      isExpanded: expandedEvents.has(event.eventId)
    })
  )

  return (
    <div className={classes.StreamingEventsListing__Wrapper}>
      <SortableDataGrid
        columns={columns}
        rows={rows}
        defaultSort={{ timestamp: 'DESC' }}
        className={classes.StreamingEventsListing__DataGrid}
        rowClassName={classes.StreamingEventsListing__Row}
        data-testid={'streaming-events-listing'}
      />
    </div>
  )
}

export default StreamingEventsListing
