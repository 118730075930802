import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Navigate, useNavigate, useParams, type To } from 'react-router-dom'

import { type AxiosError } from 'axios'
import { type FormikHelpers } from 'formik'

import { Loader, Toaster } from '@matillion/component-library'

import { createGetWarehouseComputeResourceMutationData } from 'api/createProjectForm/mutation'
import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import {
  useGetEnvironments,
  useGetMha,
  useGetProject,
  useMutateWarehouseDefaults,
  usePostEnvironment,
  usePostEnvironmentV2,
  usePostSecretReference,
  usePostSecretValueV2
} from 'api/hooks'
import { useMutateDatabricksComputeResources } from 'api/hooks/useMutateDatabricksComputeResources'
import {
  createEnvironmentMutationData,
  createSecretReferenceMutationData,
  createSecretReferenceMutationDataPassphrase,
  createSecretReferenceMutationDataPrivateKey,
  createSecretValueV2MutationData,
  createWarehouseDefaultsMutation
} from 'api/mutation'
import { type ErrorResponse } from 'api/types'

import Form from 'components/Form'

import { QueryKey } from 'constants/endpoint'
import { ENVIRONMENT_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECTS_DEFAULTS
} from 'constants/route'
import { StatusCodes } from 'constants/statusCodes'

import { useFormNavigation } from 'hooks'
import { useFlags } from 'hooks/flags'

import ConfigureEnvironmentFormsRoutes from 'modules/Projects/CreateEnvironment/ConfigureRoutes'
import {
  initialValues,
  steps
} from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/CreateEnvironmentForm.util'
import { useValidationSchema } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/hooks'
import { type CreateEnvironmentFormikValueTypes } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/types'

import { AgentsSecretsHost, SecretReferenceTypes, Warehouse } from 'types'

const CreateEnvironmentForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {
    data: projectData,
    isLoading: projectDataLoading,
    isError: projectError
  } = useGetProject(projectId!)
  const { enableKeyPairAuthentication } = useFlags()
  const { mutateAsync: mutateAsyncEnvironment } = usePostEnvironment(projectId!)
  const { mutateAsync: mutateAsyncEnvironmentV2 } = usePostEnvironmentV2(
    projectId!
  )
  const { isLastStep, nextStep, progress, stepIndex } = useFormNavigation(steps)
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync: mutateAsyncSecretReference } = usePostSecretReference()

  const { mutateAsync: mutateAsyncSecretValueV2 } = usePostSecretValueV2()
  const { refetch } = useGetEnvironments(projectId!)
  const { refetch: mhaRefetch } = useGetMha({ options: { enabled: false } })

  const validationSchema = useValidationSchema()

  const projectName = projectData?.name as string
  const projectType = projectData?.warehouse ?? ''
  const secretLocationId = projectData?.secretLocationIds[0] as string
  const agentAndSecretHostLocation =
    projectData?.agentAndSecretHostLocation ?? AgentsSecretsHost.CustomerHosted

  const { mutateAsync: mutateAsyncWarehouseRoleLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE)
  const { mutateAsync: mutateAsyncWarehouseDatabaseLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE)
  const { mutateAsync: mutateAsyncWarehouseComputeResourceLookup } =
    useMutateDatabricksComputeResources()

  const handleOnSubmit = () => {
    refetch()
    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS),
      {
        replace: true
      }
    )
  }

  const publishEnvironment = async (
    values: CreateEnvironmentFormikValueTypes,
    onPublishStatus: (success: boolean, environmentName?: string) => void,
    onFinish: () => void
  ) => {
    const mutateFunction =
      enableKeyPairAuthentication && values.type === Warehouse.Snowflake
        ? mutateAsyncEnvironmentV2
        : mutateAsyncEnvironment
    return mutateFunction({
      values: createEnvironmentMutationData(
        values,
        projectName,
        !!enableKeyPairAuthentication && values.type === Warehouse.Snowflake
      )
    })
      .then((res) => {
        onPublishStatus(true, res.name)
        queryClient.invalidateQueries([QueryKey.SECRET_REFERENCES, projectId])
      })
      .catch((_e) => {
        onPublishStatus(false)
      })
      .finally(onFinish)
  }

  const submitForm = async (
    newValues: CreateEnvironmentFormikValueTypes,
    {
      resetForm,
      setSubmitting
    }: FormikHelpers<CreateEnvironmentFormikValueTypes>
  ) => {
    clearToasts()
    setSubmitting(true)

    newValues = {
      ...newValues
    }
    if (isLastStep) {
      await publishEnvironment(
        newValues,
        (success, environmentName) => {
          if (success) {
            makeToast({
              title: t('createEnvironment.responseMessage.success', {
                environmentName
              }),
              message: '',
              type: 'success'
            })

            window.sessionStorage.removeItem(ENVIRONMENT_STORAGE_ITEM)
            handleOnSubmit()
            return
          }

          makeToast({
            title: t('createEnvironment.responseMessage.error'),
            message: '',
            type: 'error'
          })
        },
        () => {
          setSubmitting(false)
        }
      )
    } else {
      try {
        if (nextStep === PROJECTS_DEFAULTS) {
          let response
          let passphraseResponse
          if (
            newValues.agentsSecretsManagement ===
            AgentsSecretsHost.MatillionHosted
          ) {
            // TO-DO: Sad path if the agent isn't ready -  SEPE:928
            const { data: currentMha = { agentId: '' } } = await mhaRefetch()
            response = await mutateAsyncSecretValueV2({
              values: createSecretValueV2MutationData({
                ...newValues,
                name: newValues.environmentName,
                secretValue: {
                  password: newValues.secretValue
                },
                matillionHostedAgentId: currentMha.agentId
              })
            })

            if (newValues.passphrase && enableKeyPairAuthentication) {
              passphraseResponse = await mutateAsyncSecretValueV2({
                values: createSecretValueV2MutationData({
                  ...newValues,
                  name: newValues.environmentName,
                  secretValue: {
                    password: newValues.passphrase
                  },
                  matillionHostedAgentId: currentMha.agentId
                })
              })
            }
            newValues = {
              ...newValues,
              matillionHostedAgentId: currentMha.agentId
            }
          } else {
            if (newValues.secretName.id && newValues.secretKey.id) {
              response = await mutateAsyncSecretReference({
                values: createSecretReferenceMutationData(
                  newValues,
                  SecretReferenceTypes.DWH_PASSWORD
                )
              })
            } else {
              response = await mutateAsyncSecretReference({
                values: createSecretReferenceMutationDataPrivateKey(
                  newValues,
                  SecretReferenceTypes.DWH_PASSWORD
                )
              })
            }

            if (
              newValues.passphraseSecretName.id &&
              newValues.passphraseSecretKey.id &&
              enableKeyPairAuthentication
            ) {
              passphraseResponse = await mutateAsyncSecretReference({
                values: createSecretReferenceMutationDataPassphrase(
                  newValues,
                  SecretReferenceTypes.DWH_PASSWORD
                )
              })
            }
          }
          newValues = {
            ...newValues,
            secretReferenceId: response.secretId,
            passphraseSecretReferenceId: passphraseResponse?.secretId,
            defaultRole: initialValues.defaultRole,
            defaultDatabase: initialValues.defaultDatabase,
            compute: initialValues.compute
          }

          // Ensure that there is a connection to warehouse defaults role in order to continue
          // Otherwise it throws an error which is caught below and does not proceed to the next page
          switch (newValues.type) {
            case Warehouse.Snowflake:
              await mutateAsyncWarehouseRoleLookup({
                values: createWarehouseDefaultsMutation(
                  newValues,
                  !!enableKeyPairAuthentication
                )
              })
              break
            case Warehouse.Redshift:
              await mutateAsyncWarehouseDatabaseLookup({
                values: createWarehouseDefaultsMutation(newValues)
              })
              break
            case Warehouse.Databricks:
              await mutateAsyncWarehouseComputeResourceLookup({
                values: createGetWarehouseComputeResourceMutationData(newValues)
              })
              break
          }
        }

        resetForm({ values: newValues })
        navigate(nextStep as To, { replace: true })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>
        const errorDetail = (err?.response?.data as ErrorResponse)?.detail
        const errorResponseStatus = err?.response?.status

        makeToast({
          title: t('error.unexpected.title'),
          message:
            errorResponseStatus === StatusCodes.BAD_REQUEST &&
            errorDetail !== undefined
              ? errorDetail
              : t('error.unexpected.message'),
          type: 'error'
        })
      } finally {
        setSubmitting(false)
      }
    }
  }

  if (projectDataLoading) {
    return <Loader />
  }

  if (projectError) {
    return (
      <Navigate
        to={AppRoutes.getProjectDetails(
          projectId!,
          PROJECT_DETAILS_ENVIRONMENTS
        )}
      />
    )
  }

  return (
    <Form<CreateEnvironmentFormikValueTypes>
      formikValues={{
        onSubmit: submitForm,
        initialValues: {
          ...initialValues,
          agentsSecretsManagement: agentAndSecretHostLocation,
          type: projectType,
          secretLocationId: secretLocationId
        },
        validationSchema: validationSchema,
        initialTouched: false
      }}
      stepInfo={{
        stepIndex,
        isLastStep,
        progress
      }}
      translationPrefix="createEnvironment"
      persistingStorageId={ENVIRONMENT_STORAGE_ITEM}
      persistenceExclusions={['secretValue']}
    >
      <ConfigureEnvironmentFormsRoutes />
    </Form>
  )
}
export default CreateEnvironmentForm
