import { useEffect, useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import { createGetWarehouseComputeResourceMutationData } from 'api/createProjectForm/mutation'
import { type GETDatabricksComputeResourcesResponse } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'
import { useMutateDatabricksComputeResources } from 'api/hooks/useMutateDatabricksComputeResources'

import classes from 'components/Form/Form.module.scss'

import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'

import ComputeInput from './ComputeInput'

const Compute: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    initialValues,
    setFieldTouched,
    setFieldValue
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const onStatusRefresh = (data: GETDatabricksComputeResourcesResponse) => {
    if (values.compute) {
      const selectedField = data.find(
        (item) => item.clusterId === values.compute.clusterId
      )
      setFieldValue('compute', {
        ...selectedField,
        id: selectedField?.clusterId ?? '',
        name: selectedField?.clusterName ?? ''
      })
    }
  }

  const {
    data: listOfComputeResources = [],
    isLoading,
    isError: fetchingError,
    mutate: mutateDatabricksComputeResources
  } = useMutateDatabricksComputeResources({
    options: {
      onSuccess: onStatusRefresh
    }
  })

  const isFieldEnabled =
    !!values.secretReferenceId && !!values.account && !!values.username

  listOfComputeResources.sort((a, b) =>
    a.clusterName.localeCompare(b.clusterName)
  )

  const computeFormikError = renderFormikError(
    errors.compute,
    Boolean(touched.compute)
  )

  const computeError = useMemo(() => {
    if (fetchingError) {
      return t('fields.compute.error.loadingError')
    }

    if (computeFormikError && listOfComputeResources.length) {
      return t(computeFormikError)
    }

    if (listOfComputeResources.length === 0 && !isLoading) {
      return t('fields.compute.error.noItemsFound')
    }
    return null
  }, [
    computeFormikError,
    fetchingError,
    isLoading,
    listOfComputeResources.length,
    t
  ])

  const listenerFieldValue = values.secretReferenceId
  const initialListenerFieldValue = initialValues.secretReferenceId

  useEffect(() => {
    if (listenerFieldValue !== initialListenerFieldValue || !isFieldEnabled) {
      setFieldTouched('compute', false)
      setFieldValue('compute', {
        id: '',
        name: '',
        clusterId: '',
        clusterName: '',
        clusterType: '',
        state: ''
      })
    }
    if (isFieldEnabled) {
      mutateDatabricksComputeResources({
        values: createGetWarehouseComputeResourceMutationData(values)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFieldEnabled,
    mutateDatabricksComputeResources,
    setFieldTouched,
    listenerFieldValue
  ])

  const handleClick = () => {
    mutateDatabricksComputeResources({
      values: createGetWarehouseComputeResourceMutationData(values)
    })
  }

  return (
    <div data-testid="databricks-defaults-compute">
      <Field
        title={t('fields.compute.title')}
        name="compute"
        inputComponent={ComputeInput}
        availableItems={listOfComputeResources.map((computeResource) => ({
          id: computeResource.clusterId,
          name: computeResource.clusterName,
          clusterId: computeResource.clusterId,
          clusterName: computeResource.clusterName,
          clusterType: computeResource.clusterType,
          state: computeResource.state
        }))}
        loading={isLoading}
        value={values.compute}
        placeholder={
          fetchingError
            ? t('fields.compute.error.placeholderText')
            : t('fields.compute.placeholderText')
        }
        onOpen={handleClick}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.compute.supportText')}
        errorText={isLoading ? undefined : computeError}
        hasError={
          Boolean(errors?.compute?.id) && Boolean(touched.compute) && !isLoading
        }
        required
        disabled={fetchingError && !isLoading}
      />
    </div>
  )
}

export default Compute
