import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'

import { Typography } from '@matillion/component-library'

import { StreamingEventLevel, StreamingEventType } from 'types/Streaming'

import classes from './EventBadge.module.scss'

interface EventBadgeProps {
  eventLevel: StreamingEventLevel
  eventType: StreamingEventType
}

type validBadgeColours = 'red' | 'grey'

export const EventBadge: FC<EventBadgeProps> = ({ eventLevel, eventType }) => {
  const { t } = useTranslation()

  let colour: validBadgeColours
  let text: string
  if (eventType === StreamingEventType.PIPELINE_STOPPED) {
    if (eventLevel === StreamingEventLevel.ERROR) {
      colour = 'red'
      text = t('streamingPipelineDashboard.events.badge.error')
    } else {
      colour = 'grey'
      text = t('streamingPipelineDashboard.events.badge.stop')
    }
  } else {
    colour = 'grey'
    text = t('streamingPipelineDashboard.events.badge.run')
  }

  return (
    <Typography
      as="span"
      format="mc"
      weight="bold"
      className={classnames(
        classes.EventBadge,
        classes[`EventBadge--${colour}`]
      )}
    >
      {text}
    </Typography>
  )
}
