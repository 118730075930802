import { boolean, number, object, string } from 'yup'
import { type OptionalObjectSchema } from 'yup/lib/object'
import { type AnyObject } from 'yup/lib/types'

import { repositorySelectionFormValidationSchema } from '@matillion/git-component-library'

import {
  PROJECTS_AGENT_DEPLOYMENT,
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CONFIGURATION,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECTS_NEW_PROJECT,
  PROVIDER_SELECTION,
  REPOSITORY_SELECTION
} from 'constants/route'

import { useCreateProjectsContext } from 'context'

import { useFlags } from 'hooks/flags'

import {
  AgentsSecretsHost,
  GitProvider,
  ProjectConfigurationType,
  Warehouse
} from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'
import type { AutoCompleteProps } from 'types/FormTypes'

import {
  defaultSteps,
  redshiftLocationDefaultsOverwriteSteps,
  stepsWithAdvancedConfiguration,
  type CreateFormNavigationStepsType
} from './CreateProjectForm.steps'
import type {
  CreateFormNavigationSteps,
  CreateProjectFormikValueTypes
} from './types'

export const useSteps = ({
  projectConfiguration
}: {
  projectConfiguration?: ProjectConfigurationType | ''
}): Partial<CreateFormNavigationStepsType> => {
  const { enableRedshiftLocationDefaults } = useFlags()
  const { projectType } = useCreateProjectsContext()

  let createProjectSteps: Partial<CreateFormNavigationStepsType> = defaultSteps

  if (projectConfiguration === ProjectConfigurationType.Advanced) {
    createProjectSteps = {
      ...createProjectSteps,
      ...stepsWithAdvancedConfiguration
    }
  }

  // TO-DO: overwriting the steps here isn't ideal, but we'd need to add another 4 sets of steps to
  // make it play nicely with all the existing flagged steps above, this can be simplified when
  // the flags are cleaned up.
  if (projectType) {
    if (enableRedshiftLocationDefaults) {
      createProjectSteps = {
        ...createProjectSteps,
        ...redshiftLocationDefaultsOverwriteSteps[projectType]
      }
    }
  }

  return createProjectSteps
}

export const previousStepOrDefault = (
  step: CreateFormNavigationSteps,
  allSteps: Partial<CreateFormNavigationStepsType>
) => allSteps[step]?.previousStep ?? PROJECTS_NEW_PROJECT

export const alphaNumericMildCharsRegEx = /^([\w()-]\s?)+$/
export const snowflakeAccountRegex = /^[a-z][a-z0-9]*([._-][a-z0-9]+)*$/i
export const alphaNumericMildCharsWithLeadEndSpacesRegEx =
  /^\s*([\w()-]\s?)+\s*$/

export const getInitialValues = (
  values?: CreateProjectFormikValueTypes
): CreateProjectFormikValueTypes => ({
  projectName: '',
  description: '',
  type: '',
  environmentDefaultAccess: {
    id: '',
    name: ''
  },
  agentsSecretsManagement: AgentsSecretsHost.MatillionHosted,
  projectConfiguration: ProjectConfigurationType.Matillion,
  provider: GitProvider.Matillion,
  providerParameters: {},
  repositoryError: false,
  repositoryValidationLoading: false,
  repository: '',
  environmentName: '',
  etlAgent: {
    id: '',
    name: '',
    agentCloudProvider: AgentCloudProvider.AWS
  },
  matillionHostedAgentId: '',
  account: '',
  ssl: null,
  port: null,
  username: '',
  secretName: {
    id: '',
    name: ''
  },
  passphraseSecretName: {
    id: '',
    name: ''
  },
  secretKey: {
    id: '',
    name: ''
  },
  passphraseSecretKey: {
    id: '',
    name: ''
  },
  secretValue: '',
  defaultRole: {
    id: '',
    name: ''
  },
  defaultWarehouse: {
    id: '',
    name: ''
  },
  defaultDatabase: {
    id: '',
    name: ''
  },
  defaultSchema: {
    id: '',
    name: ''
  },
  secretLocationId: '',
  secretReferenceId: '',
  passphraseSecretReferenceId: '',
  awsSecretReferenceId: '',
  compute: {
    id: '',
    name: '',
    clusterId: '',
    clusterName: '',
    clusterType: ''
  },
  catalog: {
    id: '',
    name: ''
  },
  awsAccessKeyId: '',
  awsSecretAccessKey: '',
  defaultStorageLocation: { name: '', id: '' },
  credentialsType: {
    id: 'password',
    name: 'Username and password'
  },
  passphrase: '',
  privateKeySecretName: {
    id: '',
    name: ''
  },
  ...values
})

export const getGenericValuesSchemaMap = (availableProjects: string[] = []) => {
  return {
    [PROJECTS_NEW_PROJECT]: object({
      projectName: string()
        .required('fields.projectName.error.required')
        .test('projectName', 'fields.projectName.error.notUnique', (value) => {
          if (availableProjects.length === 0) {
            return true
          }
          return availableProjects.findIndex((p) => p === value) === -1
        })
        .matches(
          alphaNumericMildCharsWithLeadEndSpacesRegEx,
          'fields.projectName.error.regEx'
        ),
      description: string(),
      type: string()
        .required('fields.type.error.required')
        .matches(alphaNumericMildCharsRegEx, 'fields.type.error.regEx')
    }),
    [PROJECTS_AGENT_DEPLOYMENT]: object({
      agentsSecretsManagement: string().required(
        'fields.agentsSecretsManagement.error.required'
      )
    }),
    [PROJECTS_CREATE_ENVIRONMENT]: object({
      environmentName: string()
        .required('fields.environmentName.error.required')
        .matches(
          alphaNumericMildCharsWithLeadEndSpacesRegEx,
          'fields.environmentName.error.regEx'
        ),
      etlAgent: object({
        id: string().required('fields.etlAgent.error.required'),
        name: string().required('fields.etlAgent.error.required')
      })
        .when('agentsSecretsManagement', {
          is: AgentsSecretsHost.MatillionHosted,
          then: (_existingSchema) => {
            return object({
              id: string().optional(),
              name: string().optional()
            })
          }
        })
        .required('fields.etlAgent.error.unmatched')
        .nullable()
    }),
    [PROJECTS_CREATE_AGENT]: object({}),
    [PROJECTS_CONFIGURATION]: object({
      projectConfiguration: string().required()
    }),
    [PROVIDER_SELECTION]: object({}), // We don't use our standard form validation, instead we make use of the exposed GCL onSuccess auth callback.
    [REPOSITORY_SELECTION]:
      repositorySelectionFormValidationSchema as unknown as OptionalObjectSchema<AnyObject>
  }
}

const createSecretSchema = (fieldName: 'secretName' | 'secretKey') => {
  return object({
    id: string().required(`fields.${fieldName}.error.required`),
    name: string().required(`fields.${fieldName}.error.required`)
  })
    .when('agentsSecretsManagement', {
      is: AgentsSecretsHost.MatillionHosted,
      then: () =>
        object({
          id: string().nullable(),
          name: string().nullable()
        })
    })
    .when('credentialsType', {
      is: (credentialsType: AutoCompleteProps) =>
        credentialsType.id === 'key_pair',
      then: () =>
        object({
          id: string().nullable(),
          name: string().nullable()
        }).nullable()
    })
    .required(`fields.${fieldName}.error.unmatched`)
    .nullable()
}

const genericWarehouseSchema = (isSecretValueOptional: boolean) => ({
  secretName: createSecretSchema('secretName'),
  secretKey: createSecretSchema('secretKey').when(
    'etlAgent.agentCloudProvider',
    {
      is: AgentCloudProvider.AZURE,
      then: () =>
        object({
          id: string().nullable(),
          name: string().nullable()
        })
    }
  ),
  secretValue: string()
    .optional()
    .when(['agentsSecretsManagement', 'credentialsType'], {
      is: (agentsSecretsManagement: AgentsSecretsHost) =>
        agentsSecretsManagement === AgentsSecretsHost.MatillionHosted,
      then: (existingSchema) => {
        if (isSecretValueOptional) {
          return existingSchema
        } else {
          return existingSchema.required('fields.secretValue.error.required')
        }
      },
      otherwise: (existingSchema) => existingSchema.optional()
    })
})

export const warehouseValuesSchema = (
  warehouse: Warehouse | '',
  isSecretValueOptional = false,
  enableKeyPair = false
): {
  [PROJECTS_CREDENTIALS]: OptionalObjectSchema<AnyObject>
  [PROJECTS_CLOUD_CREDENTIALS]: OptionalObjectSchema<AnyObject>
  [PROJECTS_DEFAULTS]: OptionalObjectSchema<AnyObject>
} => {
  switch (warehouse) {
    case Warehouse.Snowflake:
      return {
        [PROJECTS_CREDENTIALS]: enableKeyPair
          ? object({
              credentialsType: object({
                id: string().required('fields.credentialsType.error.required'),
                name: string().required('fields.credentialsType.error.required')
              })
                .required('fields.credentialsType.error.unmatched')
                .nullable(),
              passphrase: string().optional(),
              account: string()
                .required('fields.account.error.required')
                .matches(snowflakeAccountRegex, 'fields.account.error.regEx'),
              username: string().required('fields.username.error.required'),
              ...genericWarehouseSchema(isSecretValueOptional)
            })
          : object({
              account: string()
                .required('fields.account.error.required')
                .matches(snowflakeAccountRegex, 'fields.account.error.regEx'),
              username: string().required('fields.username.error.required'),
              ...genericWarehouseSchema(isSecretValueOptional)
            }),
        [PROJECTS_CLOUD_CREDENTIALS]: object({}),
        [PROJECTS_DEFAULTS]: object({
          defaultRole: object({
            id: string().required('fields.defaultRole.error.required'),
            name: string().required('fields.defaultRole.error.required')
          })
            .required('fields.defaultRole.error.unmatched')
            .nullable(),
          defaultWarehouse: object({
            id: string().required('fields.defaultWarehouse.error.required'),
            name: string().required('fields.defaultWarehouse.error.required')
          })
            .required('fields.defaultWarehouse.error.unmatched')
            .nullable(),
          defaultDatabase: object({
            id: string().required('fields.defaultDatabase.error.required'),
            name: string().required('fields.defaultDatabase.error.required')
          })
            .required('fields.defaultDatabase.error.unmatched')
            .nullable(),
          defaultSchema: object({
            id: string().required('fields.defaultSchema.error.required'),
            name: string().required('fields.defaultSchema.error.required')
          })
            .required('fields.defaultSchema.error.unmatched')
            .nullable()
        })
      }
    case Warehouse.Redshift:
      return {
        [PROJECTS_CREDENTIALS]: object({
          account: string().required('fields.endpoint.error.required'),
          username: string().required('fields.username.error.required'),
          useSSL: boolean().optional(),
          port: number().required('fields.port.error.required'),
          ...genericWarehouseSchema(isSecretValueOptional)
        }),
        [PROJECTS_CLOUD_CREDENTIALS]: object({
          awsAccessKeyId: string().required(
            'fields.awsAccessKeyId.error.required'
          ),
          awsSecretAccessKey: string().required(
            'fields.awsSecretAccessKey.error.required'
          )
        }),
        [PROJECTS_DEFAULTS]: object({
          defaultSchema: object({
            id: string().required('fields.defaultSchema.error.required'),
            name: string().required('fields.defaultSchema.error.required')
          })
            .required('fields.defaultSchema.error.unmatched')
            .nullable(),
          defaultDatabase: object({
            id: string().required('fields.defaultDatabase.error.required'),
            name: string().required('fields.defaultDatabase.error.required')
          })
            .required('fields.defaultDatabase.error.unmatched')
            .nullable(),
          defaultStorageLocation: object({
            id: string().required(
              'fields.defaultStorageLocation.error.required'
            ),
            name: string().required(
              'fields.defaultStorageLocation.error.required'
            )
          })
            .when('awsSecretReferenceId', {
              is: (awsSecretReferenceId: string | undefined) =>
                !awsSecretReferenceId || awsSecretReferenceId === '',
              then: (_existingSchema) => {
                return object({
                  id: string().nullable(),
                  name: string().nullable()
                })
              }
            })
            .required('fields.defaultStorageLocation.error.unmatched')
            .nullable()
        })
      }
    case Warehouse.Databricks:
      return {
        [PROJECTS_CREDENTIALS]: object({
          account: string().required('fields.instanceName.error.required'),
          username: string().required('fields.username.error.required'),
          ...genericWarehouseSchema(isSecretValueOptional)
        }),
        [PROJECTS_CLOUD_CREDENTIALS]: object({}),
        [PROJECTS_DEFAULTS]: object({
          compute: object({
            id: string().required('fields.compute.error.required'),
            name: string().required('fields.compute.error.required'),
            clusterId: string().required('fields.compute.error.required'),
            clusterName: string().required('fields.compute.error.required'),
            clusterType: string().required('fields.compute.error.required')
          })
            .required('fields.compute.error.unmatched')
            .nullable(),
          catalog: object({
            id: string().required('fields.catalog.error.required'),
            name: string().required('fields.catalog.error.required')
          })
            .required('fields.catalog.error.unmatched')
            .nullable(),
          defaultSchema: object({
            id: string().required('fields.defaultSchema.error.required'),
            name: string().required('fields.defaultSchema.error.required')
          })
            .required('fields.defaultSchema.error.unmatched')
            .nullable()
        })
      }
    default:
      return {
        [PROJECTS_DEFAULTS]: object({}),
        [PROJECTS_CLOUD_CREDENTIALS]: object({}),
        [PROJECTS_CREDENTIALS]: object({})
      }
  }
}
