import { mixed, object, string } from 'yup'

import { type ClusterType } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'
import { type GETEnvironmentsResponseParams } from 'api/types'

import { PROJECTS_CREDENTIALS, PROJECTS_DEFAULTS } from 'constants/route'

import { initialValues as emptyInitialValues } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm'
import {
  alphaNumericMildCharsWithLeadEndSpacesRegEx,
  warehouseValuesSchema
} from 'modules/Projects/CreateProject/CreateProjectForm'
import { type EditEnvironmentFormikValueTypes } from 'modules/Projects/EditEnvironment/types'

import { AgentsSecretsHost, type Warehouse } from 'types'
import { AgentCloudProvider } from 'types/AgentCloudProvider'

export const editEnvironmentSchema = (projectType: Warehouse | '') => ({
  [PROJECTS_CREDENTIALS]: object({
    environmentName: string()
      .required('fields.environmentName.error.required')
      .matches(
        alphaNumericMildCharsWithLeadEndSpacesRegEx,
        'fields.environmentName.error.regEx'
      ),
    etlAgent: object({
      id: string().required('fields.etlAgent.error.required'),
      name: string().required('fields.etlAgent.error.required'),
      agentCloudProvider: mixed<AgentCloudProvider>()
        .oneOf(Object.values(AgentCloudProvider))
        .required()
    })
      .when('agentsSecretsManagement', {
        is: AgentsSecretsHost.MatillionHosted,
        then: (_existingSchema) => {
          return object({
            id: string().optional(),
            name: string().optional(),
            agentCloudProvider: string().optional()
          })
        }
      })
      .required('fields.etlAgent.error.unmatched')
      .nullable()
  }).concat(warehouseValuesSchema(projectType, true).credentials),
  [PROJECTS_DEFAULTS]: warehouseValuesSchema(projectType).defaults
})

export const initialEditEnvironmentValues = (
  environmentFromResponse: GETEnvironmentsResponseParams,
  agentName: string,
  secretLocation: string,
  agentCloudProvider: string,
  defaultRole: string,
  t: (arg: string) => string
): EditEnvironmentFormikValueTypes => ({
  ...emptyInitialValues,
  environmentName: environmentFromResponse.name,
  etlAgent: {
    id: environmentFromResponse.agentId,
    name: agentName,
    agentCloudProvider: agentCloudProvider
  },
  matillionHostedAgentId: environmentFromResponse.agentId,
  agentsSecretsManagement:
    agentName === 'Matillion Hosted Agent'
      ? AgentsSecretsHost.MatillionHosted
      : AgentsSecretsHost.CustomerHosted,
  environmentDefaultAccess: {
    id: defaultRole,
    name: t(`fields.environmentDefaultAccess.options.${defaultRole}`)
  },
  account: environmentFromResponse.warehouseConnection.url,
  username: environmentFromResponse.warehouseConnection.username,
  secretName: {
    id: environmentFromResponse.warehouseConnection.secretName,
    name: environmentFromResponse.warehouseConnection.secretName
  },
  secretKey: {
    id: environmentFromResponse.warehouseConnection.secretKey,
    name: environmentFromResponse.warehouseConnection.secretKey
  },
  ...(environmentFromResponse.warehouseConnection?.defaultRole && {
    defaultRole: {
      id: environmentFromResponse.warehouseConnection.defaultRole,
      name: environmentFromResponse.warehouseConnection.defaultRole
    }
  }),
  ...(environmentFromResponse.warehouseConnection?.defaultDatabase && {
    defaultDatabase: {
      id: environmentFromResponse.warehouseConnection.defaultDatabase,
      name: environmentFromResponse.warehouseConnection.defaultDatabase
    }
  }),
  ...(environmentFromResponse.warehouseConnection?.defaultSchema && {
    defaultSchema: {
      id: environmentFromResponse.warehouseConnection.defaultSchema,
      name: environmentFromResponse.warehouseConnection.defaultSchema
    }
  }),
  ...(environmentFromResponse.warehouseConnection?.defaultWarehouse && {
    defaultWarehouse: {
      id: environmentFromResponse.warehouseConnection.defaultWarehouse,
      name: environmentFromResponse.warehouseConnection.defaultWarehouse
    }
  }),
  secretReferenceId: environmentFromResponse.warehouseConnection.secretId,
  secretLocationId: secretLocation,
  type: environmentFromResponse.warehouseConnection.type as Warehouse,
  port: environmentFromResponse.warehouseConnection.port,
  ssl: environmentFromResponse.warehouseConnection.ssl,
  ...(environmentFromResponse.warehouseConnection?.clusterId &&
    environmentFromResponse.warehouseConnection?.clusterName &&
    environmentFromResponse.warehouseConnection?.clusterType && {
      compute: {
        id: environmentFromResponse.warehouseConnection.clusterId,
        name: environmentFromResponse.warehouseConnection.clusterName,
        clusterId: environmentFromResponse.warehouseConnection.clusterId,
        clusterName: environmentFromResponse.warehouseConnection.clusterName,
        clusterType: environmentFromResponse.warehouseConnection
          .clusterType as ClusterType
      }
    }),
  ...(environmentFromResponse.warehouseConnection?.defaultCatalog && {
    catalog: {
      id: environmentFromResponse.warehouseConnection.defaultCatalog,
      name: environmentFromResponse.warehouseConnection.defaultCatalog
    }
  })
})
